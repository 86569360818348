header{background:#fff;position:fixed;left:0;top:0;z-index:999;width:100%;padding:17px 0;box-shadow:0 4px 12px rgb(138 138 138 / 12%)}
.navbar{padding:0}
.navbar-inner-box{width:100%}
.btn-register{min-width:119px}
.navbar-expand-lg .navbar-nav{flex-direction:column}
.inner-header .navbar-collapse{background-color:#3d4cd2;position:fixed;top:0;right:-321px;z-index:9;width:321px;transition:.3s;padding-top:65px}
.navbar-nav{overflow:auto;height:calc(100vh - 65px);width:100%}
.inner-header .navbar-collapse.show{transition:.5s;right:0;margin-left:0}
.inner-header #close{position:absolute;top:15px;right:30px;font-size:32px;color:#fff;font-weight:300}
.inner-header .navbar-toggler-icon{display:flex;align-items:center;justify-content:center;height:42px;width:42px;background:#3d4cd2;color:#fff;border-radius:50%;font-size:24px}
.inner-header .navbar-toggler{display:block;margin:0;padding:0;border:0}
.inner-header .navbar-toggler:focus{box-shadow:none}
.inner-header .notifications-btn i{font-size:25px;color:#3d4cd2}
.inner-header .user-img img{width:42px;height:42px;border-radius:50%}
.inner-header .nav-link{padding:10px 15px 10px 25px!important;font-size:16px;color:#fff;display:flex;align-items:center;transition:.5s}
.inner-header .nav-link i{width:42px;height:42px;display:flex;align-items:center;justify-content:center;background:rgb(255 255 255 / 24%);border-radius:50%;font-size:20px;font-weight:300;margin-right:15px;transition:.5s}
.inner-header .nav-link:focus,.nav-link:hover,.logout-btn:hover{color:#3d4cd2;background:#fff;transition:.5s}
.inner-header .nav-link:hover i{background:rgb(61 76 210);color:#fff;transition:.5s}


.notification-blog{position:absolute;top:50px;right:0;background:#fff;border-radius:6px;box-shadow:0 1px 9px rgb(64 58 58 / 6%);min-width: 460px;padding:25px 25px;height:410px;overflow-y:auto;overflow-x:hidden}
.notification-blog-head h4{font-size:18px;color:#000;font-weight:700}
.notification-blog-head{margin-bottom:20px}
.notification-list-title{font-size:15px;display:flex;align-items:center;justify-content:space-between;padding-bottom:10px}
.notification-list-title small{font-size:14px;color:#a4a7b9}
.notification-list li p{font-size:14px;color:#000}
.notification-list li{border-bottom:1px solid #e4e4e4;padding-bottom:15px;margin-bottom:15px}
.notification-box{position:relative}
.notification-list-title h4{font-size:15px;font-weight:700;width: 250px;}
.notification-list li:last-child{border-bottom:0;margin-bottom:0;padding-bottom:0}

.nav-R .user-img{ margin-bottom: 0;}

a{ text-decoration: none;}
.nav-sidebar-main{background-color: #3d4cd2;width: 321px;padding-top: 65px;}
ul.nav-sidebar-inner-list {padding-left: 0;}
.logout-btn{width: 100%;background: transparent;border: none;}

.title-noti-head small {
    color: rgb(0 0 0 / 32%);
    font-size: 12px;
}
.title-noti-head h6 {
    font-size: 16px;
}
ul.notification-list li p {
    font-size: 13px;
    width: 70%;
}
.notification-list h5 {
    font-size: 18px;
}

.notifications-btn {
    position: relative;
}
small.noti-content {
    position: absolute;
    right: -6px;
    top: -11px;
    background: #254cc6;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #fff;
    font-size: 9px;
    font-weight: 700;
}
ul.notification-list li {
    margin-bottom: 5px;
    padding-bottom: 10px !IMPORTANT;
}
.title-noti-head h6{margin-bottom: 0;}

.title-noti-head {
    margin-bottom: 4px;
}
@media(max-width:480px){

.notification-list {
    width: 100% !IMPORTANT;
    right: 0 !important;
}
}