/*--------------------------------------------------------------------------------------*/
/*footer-start
/*--------------------------------------------------------------------------------------*/


footer{background:0 0;box-shadow:0 4px 12px rgb(138 138 138 / 12%);padding:13px 0;z-index:9;position:relative}
.footer-link li a{font-size:15px;color:#000!important;padding-right:25px;font-weight:500}
.all-right p{font-size:15px;color:#000;font-weight:400}

/*-----------------------------------------------------------

---------------------------*/
/*footer-end
/*--------------------------------------------------------------------------------------*/