.billing-summary-container {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.billing-summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.billing-summary-header h3 {
  margin: 0;
}

.export-btn {
  padding: 0.5rem 1rem;
}

.billing-tabs {
  margin-bottom: 1rem;
}

.billing-table {
  overflow-x: auto; /* Horizontal scrolling */
  margin-bottom: 1rem;
}

.billing-row {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
}

.billing-cell {
  flex: 1;
  text-align: left;
  padding: 0.5rem;
}

.billing-header {
  font-weight: bold;
  background-color: #f8f9fa;
}

.billing-summary {
  overflow: visible; /* Ensure scrollbar is outside */
}

@media (max-width: 768px) {
  .billing-cell {
    font-size: 0.85rem;
    padding: 0.25rem;
  }
  .billing-row {
    flex-wrap: wrap; /* Stack cells vertically on smaller screens */
  }
}

@media (max-width: 576px) {
  .billing-summary-header h3 {
    font-size: 1.25rem;
  }
  .billing-summary-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .billing-tabs {
    flex-direction: column;
  }
}
