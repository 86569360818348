@media only screen and (min-width:768px) and (max-width:1169px){}
@media only screen and (min-width:0px) and (max-width:767px){}
@media (min-width: 768px){

}

@media only screen and (max-width: 1199px) {

.home-sign-in-R .similar-shadow-box{width:100%}
.home-sign-in-R{width:50%}
.home-sign-in-L{padding-right:5px;width:50%}
.home-sign-in-L h1{font-size:45px;margin-bottom:5px}
.home-sign-in-L p{line-height:24px}
.all-group-L-list li{flex-wrap:wrap}
.all-group-L-list-R a{padding-left:0;padding-right:8px;padding-top:5px}
.member-home-main .common-content-box{width:100%}
.textarea-box .without-icon .placeholder{white-space:normal}
.link-expired .col-md-4.m-auto{width:50%}
.no-financial{width:50%}
.profile .col-md-5.m-auto{width:65%}
.slider-box .item-content figcaption{padding-right:45px}
.home-registration .col-md-5.m-auto{width:50%}
.reset-password .col-md-4.m-auto{width:50%}
.vertical-tabs-L .nav-tabs .nav-item .nav-link{padding:12px 10px 12px 20px}
.manage-admin-list li.manage-admin-list-box{margin-bottom:15px;flex-direction:column; padding: 12px;}
.manage-admin-list-box .delete-post,.manage-admin-list-box .edit-post{width:36px;height:36px;font-size:18px;margin-left:0!important;margin-right:10px;margin-top:15px}
.contact-info.d-flex{flex-direction:column}
.contact-info a{margin-right:0;margin-bottom:10px}
.max-box .modal-dialog,.rsvp-pay-box .modal-dialog{max-width:95%;margin:50px auto}
.wrapper-video{height:200px}
.home-sign-in-R .similar-shadow-box {
    width: 100%;}

    .footer-box .row {
        flex-direction: column;
      
}
.footer-link.d-flex {
    justify-content: center;
  }

  .footer-box .col-md-6 {
    flex: 0 0 100%;
    width: 100%;}

    .col-md-6.text-right.d-flex.justify-content-end.all-right {
        justify-content: center !important;
        margin-top: 8px;
      }
      .carousel-control-next-icon, .carousel-control-prev-icon{ display: none !important;}
}

@media only screen and (max-width: 991px) {
.all-group-L{width:100%}
.all-posts{width:100%;margin-top:30px;padding-left:14px;}
.min-box{height:auto}
.edit-profile .col-md-6.m-auto{width:80%}
.no-financial{width:80%}
.manage-groups .similar-shadow-box{width:100%}
.additional-text{flex-direction:column}
.profile-list li{padding:0 20px 15px}
.onboarding .item-content{flex-direction:column}
.slider-box .item-content figcaption{padding-right:0;order:2}
.slider-box .item-content figcaption{padding-right:0;order:2;text-align:center}
.slider-box .owl-carousel .owl-item img{display:block;width:300px;margin:auto;margin-bottom:34px}
.slider-box .item-content figcaption h3{font-size:32px;border-left:0;padding-left:0}
.home-registration .col-md-5.m-auto{width:80%}
.reset-password .col-md-4.m-auto{width:80%}
.vertical-tabs{flex-direction:column}
.vertical-tabs-L{width:100%;padding-bottom:0}
.vertical-tabs-L .nav-tabs{flex-direction:inherit;display:flex;flex-wrap:nowrap;overflow:auto;overflow-y:hidden;white-space:nowrap}
.vertical-tabs-L{width:100%;padding-bottom:0;border-bottom-left-radius:0;border-bottom-right-radius:0;border-top-right-radius:10px}
.vertical-tabs-L .nav-tabs .nav-item .nav-link{padding:12px 20px 12px 20px}
.vertical-tabs-L-box-head{padding:20px 15px 25px}
.vertical-tabs-L-box-head h3{font-size:22px}

.slider-content-box {
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.slider-box .carousel-item figcaption{padding: 0px 15px;}
.slider-box .carousel-item figcaption h3{ border-left: 0;}
.slider-box .carousel-item figcaption h3{font-size: 34px;margin-bottom: 10px;}
.slider-content-box figure img {
  height: 300px !important;
  width: auto !important;

  margin: auto !important;
}
.all-posts-box .postmedia img,
.all-posts-box .postmedia video{
  width :100%;
}

.file-media-img {
  max-width: 100% !important;
}

}

@media only screen and (max-width: 767px) {
.home-sign-in-L{padding-right:5px;width:100%}
.home-sign-in-R{width:100%;margin-top:40px}
.home-sign-in-L{display:none}
.home-sign-in-R{width:100%;margin-top:0}
.footer-link.d-flex{justify-content:center}
.all-right{justify-content:center!important;margin-top:10px}
.home-btn-group a{font-size:14px}
.all-group-L{width:100%;margin-bottom:25px}
.similer-tabs{white-space:nowrap;overflow:auto;flex-wrap:nowrap}
.head-btn{align-items:center;margin-bottom:20px}
.head-btn.justify-content-between .inner-title{margin-bottom:0!important}
.radio-check-box{flex-direction:column}
.breadcrumb li.breadcrumb-item{font-size:14px}
.link-expired .col-md-4.m-auto{width:80%}
.bank-info-list-R .delete-post,.bank-info-list-R .edit-post{width:35px;height:35px}
.profile .col-md-5.m-auto{width:100%}
.slider-box .item-content figcaption h3{font-size:28px;margin-bottom:0}
.home-registration .col-md-5.m-auto{width:100%}
.form-head h2{font-size:26px}
.full-info-adult-box{margin-top:20px}
.home-btn-group a{font-size:14px;margin:0 3px}
.total-box li{padding:12px 10px}
.total-box li p{font-size:18px}
.home-btn-group a i{font-size:18px;padding-right:5px}
.big-btn{width:100%}
.radio-flex-box{flex-direction:column}
.similar-shadow-box{padding:20px 15px}
.edit-profile .col-md-6.m-auto{width:100%}
.btn-register{height:38px;line-height:38px;font-size:14px}
.bank-info-list-R .delete-post,.bank-info-list-R .edit-post{width:36px;height:36px}
.bank-info-list-R .delete-post i,.bank-info-list-R .edit-post i{font-size:18px}
.bank-info-list-L h4{font-size:16px}
.card-info-box-content{margin-right:0;padding-right:30px}
.no-financial{width:100%}
ul.payment-list li img{width:45px}
.profile-list li{padding:0 0 15px}
.slider-box .item-content figcaption h3{font-size:25px;margin-bottom:18px;line-height:normal}
.common-modal .modal-content{border:none;border-radius:10px;outline:0;padding:30px 20px 45px}
.common-modal .modal-head{margin-bottom:20px}
.common-modal .modal-title{font-size:24px}

.slider-box .carousel-item figcaption h3 {
  font-size: 26px;
  margin-bottom: 5px;
}
}

@media only screen and (max-width: 575px) {
.common-content-box{ width: 100%;}

.common-content-box video {
    width: 100%;
}

.swal2-popup{    width: 90%;}

.slider-content-box figure img {
  height: 200px !important;}

  .slider-box .carousel-item figcaption h3 {
    font-size: 22px;
    margin-bottom: 2px;
  }
  .slider-box .carousel-item figcaption p {
    font-size: 15px;} 

}

@media only screen and (max-width: 480px) {
.footer-link li a{font-size:12px!important}
.blue-link{font-size:14px}
.mb-4.pb-2{padding-bottom:0!important}
.all-right p{font-size:12px}
.form-head{margin-bottom:20px}
.common-modal .modal-dialog{max-width:92%}
.home-head small{font-size:15px}
.total-box li{flex-direction:column}
.total-box li span{font-size:14px}
.home-btn-group a{padding:0 10px}
.home-btn-group a{line-height:1.1}
.post-comment-list-R-head h3{font-size:14px}
.post-comment-list-R-head-right small{flex:1}
.post-comment-list-R-head-right{width:100%}
.post-comment-list-R-head{flex-wrap:wrap}
.time-info{flex-direction:column}
.time-info li{margin:2px 0}
.all-posts-footer{align-items:center}
.common-modal .modal-title{font-size:24px}
.sponsored-box-head{padding:20px 10px 0}
.all-posts-footer ul li a{margin-right:12px}
.add-zone-box.d-flex{flex-wrap:wrap}
.add-child{margin-bottom:10px}
.btn-box{margin-top:35px!important}
.head-btn{flex-wrap:wrap}
.inner-title{margin-bottom:20px!important}
.without-icon .placeholder{white-space:initial}
.common-modal .modal-content{padding:30px 25px 45px}
.link-expired .col-md-4.m-auto{width:90%}
.form-head h5{font-size:22px}
.inner-title{font-size:20px}
.bank-info-list-L span{font-size:19px}
.card-info-box{flex-wrap:wrap}
.card-info-box-content{margin-right:0;padding-right:20px}
.manage-groups-list .manage-groups-list-box{flex-direction:column}
.manage-groups-list-box-R{margin-top:15px}
.profile-head figcaption h4{font-size:20px}
.slider-box .item-content figcaption h3{font-size:20px;margin-bottom:12px}
.slider-box .item-content figcaption p{font-size:14px}
.slider-box .owl-carousel .owl-item img{width:200px}
.owl-dots button span{width:12px;height:12px}
.terms-content h4{font-size:18px}
.terms-content{margin-bottom:12px}
.reset-password .col-md-4.m-auto{width:100%}
.manage-admins-head{flex-direction:column;margin-bottom:20px}
.all-group-L-list-L h4{font-size:16px}
.info-adult-box .col-md-4{width:100%;margin-bottom:20px!important}
.full-info-adult-box{margin-top:0}
.terms-condition-box{flex-wrap: wrap;}
.manage-groups-list .rdt_TableRow{flex-direction: column;}
.manage-groups-list .rdt_TableCell{margin-bottom: 10px;}
.slider-box .carousel-item figcaption h3{font-size: 18px; margin-bottom: 10px; line-height: 1.3;}
.slider-box .carousel-item figcaption p{font-size: 14px;}
.similar-shadow-2{padding: 10px;}
.home-btn-group a{font-size: 13px;}
.inner-title{font-size: 15px;}
.add-registration-details div.d-flex{flex-wrap: wrap;}
.add-registration-details .add-child.add-btn{margin-left: 0 !important;}



.create-new-post .mb-2.d-flex{flex-wrap: wrap;}
.home-btn-group{flex-wrap: wrap;}
.home-btn-group a,.home-btn-group button{width: 100%; margin-bottom: 10px; margin-left: 0; margin-right: 0;} 
.home-btn-group a:last-child{margin-bottom: 0;}
.tab-pane .d-flex.justify-content-between{flex-wrap: wrap; margin-bottom: 15px;}
.tab-pane .d-flex.justify-content-between .inner-title{margin-bottom: 10px !important;}
.manage-groups.pb-5.small{padding-bottom: 0 !important;}
.col-md-8.all-posts{margin-top:0;}
.add-registration-details button{width: 100%;}
.calender_box{padding: 15px; overflow: auto;}
.calender_box .rbc-calendar{min-width: 300px;}
.rbc-btn-group{white-space:unset; width: 100%;}
body .rbc-toolbar .rbc-toolbar-label{padding: 10px 0px;}
.rbc-btn-group button{margin-bottom: 5px;}
.add-member.pt-4.pb-5.min-box{padding-bottom: 0 !important;}
.accordion-body ol{padding-left: 10px;}
.accordion-body video{width: 100%;}
.notification-blog{min-width: 300px; right: -105px;}
.notification-list-title h4{width: 100%;}
.notification-list-title h4{font-size: 13px;}
.notification-list-title a{text-decoration: none;}
.notification-list-title small{margin-left: 10px; font-size: 13px;}
.adult-info{margin-bottom: 15px;}
.event-title{font-size: 21px;}


}

