/*--------------------------------------------------------------------------------------*/


/*keyframe
/*--------------------------------------------------------------------------------------*/

@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }
    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg)
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes spinAround {
    from {
        -moz-transform: rotate(0deg)
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotateme {
    0% {
        transform: rotate(0deg);
        opacity: 1;
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
        opacity: 1;
    }
}


/* Bob Up Down*/

@-webkit-keyframes float_up_down {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes float_up_down {
    0% {
        transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
    50% {
        transform: translateY(30px);
        transform: translateY(30px);
        opacity: 1;
    }
    100% {
        transform: translateY(0px);
        transform: translateY(0px);
        opacity: 1;
    }
}

@-webkit-keyframes zoom-fade {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@keyframes zoom-fade {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}

.hvr-sweep-to-right:hover,
.hvr-sweep-to-right:focus,
.hvr-sweep-to-right:active {
    color: #fff !important
}

.hvr-sweep-to-right {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    overflow: hidden;
    cursor: pointer
}

.hvr-sweep-to-right:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    overflow: hidden;

}

.hvr-sweep-to-right:hover:before,
.hvr-sweep-to-right:focus:before,
.hvr-sweep-to-right:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
}

.shape-1,
.shape-2,
.shape-3,
.shape-4,
.shape-5,
.shape-6,
.shape-7,
.shape-8,
.shape-9,
.shape-10,
.shape-11 {
    animation-name: zoom-fade;
    animation-duration: 5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: zoom-fade;
    -webkit-animation-duration: 8s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: zoom-fade;
    -moz-animation-duration: 8s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: zoom-fade;
    -ms-animation-duration: 8s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: zoom-fade;
    -o-animation-duration: 8s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear
}

.banner-top-R figure:after{


    animation-name: rotateme;
    animation-duration: 22s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 22s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotateme;
    -moz-animation-duration: 22s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: rotateme;
    -ms-animation-duration: 22s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;
    -o-animation-name: rotateme;
    -o-animation-duration: 22s;
    -o-animation-iteration-count: infinite;
    -o-animation-timing-function: linear;}

@-webkit-keyframes zoom-fade {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8)
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    100% {
        -webkit-transform: scale(0.8);
        transform: scale(0.5)
    }
}

@keyframes zoom-fade {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8)
    }
    50% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
    100% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8)
    }
}
.shape-1 {
    position: var(--position-abso);
    top: 18%;
    left: -100px;
}


.shape-2 {
    position: var(--position-abso);
    bottom: 18%;
    right: -100px;
}

.shape-3 {
      bottom: 40%;
    left: -105px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: var(--position-abso);
}

.shape-4 {
    bottom: 40px;
    right: -105px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: var(--position-abso);
}
span.shape-4 img {
    transform: scaleX(-1);
    transform: rotate(20deg);
    transform: scaleX(-1);
}

.shape-5 {
    position: var(--position-abso);
    left: -116px;
    top: 61px;
    margin: auto;
    display: flex;
    align-items: center;
    transform: scaleX(-1);
    transform: scaleX(-1);
    transform: rotate(20deg);
}

.shape-6 {
    bottom: 30%;
    right: -105px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: var(--position-abso);
}






.banner-btn {
    position: relative;
    overflow: hidden;
}

/*.banner-btn:after {
    -webkit-animation: shine 3s infinite ease-in-out;
    animation: shine 3s infinite ease-in-out;
    content: "";
    position: absolute;
    top: 0;
    left: -70px;
    width: 50px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    transition: 0.2s;
    -webkit-transform: skewX(-15deg);
    transform: skewX(-15deg);
}*/


/*@-webkit-keyframes shine {
  0% {
    left: -40px;
  }
  25% {
    left: 230px;
  }
  100% {
    left: 230px;
  }
}
@keyframes shine {
  0% {
    left: -40px;
  }
  25% {
    left: 230px;
  }
  100% {
    left: 230px;
  }
}*/


/*@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}*/